<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="8">
            <el-form-item :label="'购电时间'" prop="purchaseTime">
                <el-date-picker
                    style="width: 204px"
                    v-model="formData.purchaseTime"
                    type="date"
                    :placeholder="'请选择购电时间'"
                    value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
            </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'购电月份'" prop="purchaseMonth">
                        <el-date-picker
                            v-model="formData.purchaseMonth"
                            style="width: 100%"
                            type="month"
                            :placeholder="'请选择'"
                            value-format="yyyy-MM"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电费发票金额（元）'" prop="allPowerPrice">
                        <el-input-number v-model="invoice" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'总电费（元）'" prop="allPowerPrice">
                        <el-input-number v-model="allPowerPrice" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'购电量（峰）（kw/h）'">
                        <el-input-number v-model="formData.purchasePowerPeak" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能单价（峰）（元）'">
                        <el-input-number v-model="formData.powerUnitPricePeak" :controls="false" :precision="6" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能费用（峰）（元）'" prop="powerTotalPrice">
                        <el-input-number v-model="powerTotalPricePeak" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'购电量（平）（kw/h）'">
                        <el-input-number v-model="formData.purchasePowerPacific" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能单价（平）（元）'">
                        <el-input-number v-model="formData.powerUnitPricePacific" :controls="false" :precision="6" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能费用（平）（元）'" prop="powerTotalPrice">
                        <el-input-number v-model="powerTotalPricePacific" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'购电量（谷）（kw/h）'">
                        <el-input-number v-model="formData.purchasePowerLow" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能单价（谷）（元）'" >
                        <el-input-number v-model="formData.powerUnitPriceLow" :controls="false" :precision="6" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能费用（谷）（元）'" prop="powerTotalPrice">
                        <el-input-number v-model="powerTotalPriceLow" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'购电量（总）（kw/h）'">
                        <el-input-number v-model="purchasePower" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'电能费用（总）（元）'" prop="powerTotalPrice">
                        <el-input-number v-model="powerTotalPrice" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item :label="'用电容量（kw）'" prop="powerCapacity">
                        <el-input-number v-model="formData.powerCapacity" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <!--<el-form-item :label="'基本电费单价'" prop="quantity">
                        <el-input-number v-model="formData.quantity" :precision="2" :step="1" style="width: 100%"></el-input-number>
                    </el-form-item>-->
                    <el-form-item :label="'基本电费单价（元）'" prop="powerBasicUnitPrice">
                        <el-input-number v-model="formData.powerBasicUnitPrice" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'基本电费（元）'" prop="powerBasicTotalPrice">
                        <el-input-number v-model="powerBasicTotalPrice" :disabled="true" :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="功率因数" prop="powerFactor">
                        <el-select v-model="formData.powerFactor" placeholder="请选择" @change="changer(formData.powerFactor)" :filterable="true" clearable style="width: 100%">
                            <el-option
                                :precision="2"
                                v-for="item in powerFactors"
                                :key="item.factor"
                                :label="item.factor.toFixed(2)"
                                :value="item.factor"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'调整系数'" prop="adjustmentCoefficient">
                        <el-input v-model="formData.adjustmentCoefficient" :disabled="true"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="'调整电费（元）'" prop="adjustmentPowerPrice">
                        <el-input-number v-model="adjustmentPowerPrice" :disabled="true"  :controls="false" :precision="2" style="width: 204px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'地方水利建设基金费用（元）'" prop="constructionFund">
                        <el-input-number v-model="constructionFund" :disabled="true" :controls="false" :precision="2" style="width: 660px"></el-input-number>
                        <p style="font-size: 12px; margin-top: -2px; margin-left: 10px">地方水利建设基金征收标准: 0.02元/kw/h</p>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'退补或考核电费（元）'" prop="refundOrAssessmentPrice">
                        <el-input-number v-model="formData.refundOrAssessmentPrice" :controls="false" :precision="2" style="width: 660px"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'购电凭证'" prop="files">
                        <div class="change-line">
                            <ex-uploader v-model="formData.files"></ex-uploader>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import {getPowerFactor} from '../../client';
import numeral from 'numeral';
export default {
    name: 'PowerPurchaseEditForm',
    props: {
        id: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        let validatorUnitPrice = function(rule, value, callback) {
            if (value === '' || value === null || value === '0.0' || value === '0.00') {
                return callback(new Error('请输入购电单价！'));
            } else if (!/(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/.test(value) && value < 0.01) {
                return callback(new Error('购电单价必须大于0，且最多保留2位小数!'));
            }
            callback();
        };
        let validatorQuantity = function(rule, value, callback) {
            if (value === '' || value === null || value === '0.0' || value === '0.00') {
                return callback(new Error('请输入购电电量！'));
            } else if (!/(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/.test(value) && value < 0.01) {
                return callback(new Error('购电电量必须大于0，且最多保留2位小数!'));
            }
            callback();
        };
        return {
            formData: {
                purchaseTime: '', //购电时间
                purchaseMonth: '', //购电月份
                purchasePower: null, //总购电量
                purchasePowerPeak: null, //购电量（峰）
                purchasePowerPacific: null, //购电量（平）
                purchasePowerLow: null, //购电量（谷）
                powerUnitPricePeak: null, //电能单价（峰）
                powerUnitPricePacific: null, //电能单价（平）
                powerUnitPriceLow: null, //电能单价（谷）
                powerTotalPrice: null, //电能费用（总）
                powerTotalPricePeak: null, //电能费用（峰）
                powerTotalPricePacific: null, //电能费用（平）
                powerTotalPriceLow: null, //电能费用（谷）
                powerCapacity: null, //用电容量
                powerBasicUnitPrice: null, //基本电费单价
                powerBasicTotalPrice: null, //基本电费
                powerFactor: null, //功率因数
                adjustmentPowerPrice: null, //调整电费
                constructionFund: null, //地方水利建设基金费用
                allPowerPrice: null, //总电费
                refundOrAssessmentPrice: null, //退补或考核电费
                adjustmentCoefficient: null, //调整系数
                invoice: null, //电费发票金额
                files: [],
            },
            formRules: {
                purchaseTime: [
                    {
                        required: true,
                        message: '请选择购电时间',
                        trigger: 'blur',
                    },
                ],
                purchaseMonth: [
                    {
                        required: true,
                        message: '请选择购电月份',
                        trigger: 'blur',
                    },
                ],
                purchasePower: [
                    {
                        required: true,
                        message: '请输入购电量',
                        trigger: 'blur',
                    },
                ],
                powerUnitPrice: [
                    {
                        required: true,
                        message: '请输入电能单价',
                        trigger: 'blur',
                    }
                ],
                powerCapacity: [
                    {
                        required: true,
                        message: '请输入用电容量',
                        trigger: 'blur',
                    }
                ],
                powerBasicUnitPrice: [
                    {
                        required: true,
                        message: '请输入基本电费单价',
                        trigger: 'blur',
                    }
                ],
                powerFactor: [
                    {
                        required: true,
                        message: '请输入功率因数',
                        trigger: 'blur',
                    }
                ],
                files: {
                    required: false,
                    message: '请上传购电凭证！',
                    trigger: 'blur',
                },
                /*adjustmentPowerPrice: [
                    {
                        required: true,
                        message: '调整电费不能为空',
                        trigger: 'blur',
                    }
                ],*/
                refundOrAssessmentPrice: [
                    {
                        required: true,
                        message: '退补或考核电费不能为空',
                        trigger: 'blur',
                    }
                ]
                /*powerTotalPrice: [
                    {
                        required: true,
                        message: '电能费用不能为空',
                        trigger: 'blur',
                    }
                ],
                powerBasicTotalPrice: [
                    {
                        required: true,
                        message: '基本电费不能为空',
                        trigger: 'blur',
                    }
                ],
                adjustmentCoefficient: [
                    {
                        required: true,
                        message: '调整系数不能为空',
                        trigger: 'blur',
                    }
                ],

                constructionFund: [
                    {
                        required: true,
                        message: '地方水利建设基金费用不能为空',
                        trigger: 'blur',
                    }
                ],
                allPowerPrice: [
                    {
                        required: true,
                        message: '总电费不能为空',
                        trigger: 'blur',
                    }
                ],*/
            },
            powerFactors: [],
        };
    },
    computed: {
        //电能费用（峰）
        powerTotalPricePeak() {
            //电能费用（峰） = 购电量（峰） * 购电单价（峰）
            //return this.formData.purchasePowerPeak * this.formData.powerUnitPricePeak;
            return numeral(this.formData.purchasePowerPeak || 0)
                .multiply(this.formData.powerUnitPricePeak || 0)
                .format('0.00');
        },
        //电能费用（平）
        powerTotalPricePacific() {
            //电能费用（谷） = 购电量（平） * 购电单价（平）
            return numeral(this.formData.purchasePowerPacific || 0)
                .multiply(this.formData.powerUnitPricePacific || 0)
                .format('0.00');
        },
        //电能费用（谷）
        powerTotalPriceLow() {
            //电能费用（谷） = 购电量（谷） * 购电单价（谷）
            return numeral(this.formData.purchasePowerLow || 0)
                .multiply(this.formData.powerUnitPriceLow || 0)
                .format('0.00');
        },
        //电能费用（总）
        powerTotalPrice() {
            //电能费用（总） = 电能费用（峰） *电能费用（平）+ 电能费用（谷）
            return numeral(this.powerTotalPricePeak || 0)
                .add(this.powerTotalPricePacific || 0)
                .add(this.powerTotalPriceLow || 0)
                .format('0.00');
        },
        //购电量（总）
        purchasePower () {
            //购电量（总）= 购电量（峰）+ 购电量（平）+ 购电量（谷）
            return numeral(this.formData.purchasePowerPeak || 0)
                .add(this.formData.purchasePowerPacific || 0)
                .add(this.formData.purchasePowerLow || 0)
                .format('0.00');
        },
        //基本电费
        powerBasicTotalPrice() {
            return this.formData.powerCapacity * this.formData.powerBasicUnitPrice;
        },
        //调整电费
        adjustmentPowerPrice() {
            //调整电费 =
            return numeral(this.formData.powerUnitPricePeak || 0)
                .subtract(0.044375)
                .multiply(this.purchasePower || 0)
                .add(this.powerBasicTotalPrice || 0)
                .multiply(this.formData.adjustmentCoefficient || 0)
                .format('0.00');
        },
        //总电费
        allPowerPrice() {
            //总电费+基本电费+调整电费+退补或考核电费+地方水利建设基金
            return numeral(this.powerTotalPrice || 0)
                .add(this.powerBasicTotalPrice || 0)
                .add(this.constructionFund || 0)
                .add(this.adjustmentPowerPrice || 0)
                .add(this.formData.refundOrAssessmentPrice || 0)
                .format('0.00');
        },
        constructionFund() {
            return this.purchasePower * 0.02;
        },
        invoice() {
            //总电费+基本电费+调整电费+退补或考核电费
            return numeral(this.powerTotalPrice || 0)
                .add(this.powerBasicTotalPrice || 0)
                .add(this.adjustmentPowerPrice || 0)
                .add(this.formData.refundOrAssessmentPrice || 0)
                .format('0.00');
        }
    },
    methods: {
        changer(powerFactor) {
            if (powerFactor != null && powerFactor !== '') {
                this.powerFactors.forEach(item => {
                    if (item.factor === powerFactor) {
                        this.formData.adjustmentCoefficient = item.adjustmentStandardOne;
                    }
                });
            } else {
                this.formData.adjustmentCoefficient = null;
            }
        },
        submitHandle(cb) {
            this.$refs.form.validate((valid) => {
                this.formData.powerTotalPricePeak = this.powerTotalPricePeak;
                this.formData.powerTotalPricePacific = this.powerTotalPricePacific;
                this.formData.powerTotalPriceLow = this.powerTotalPriceLow;
                this.formData.powerTotalPrice = this.powerTotalPrice;
                this.formData.purchasePower = this.purchasePower;
                this.formData.powerBasicTotalPrice = this.powerBasicTotalPrice;
                this.formData.adjustmentPowerPrice = this.adjustmentPowerPrice;
                this.formData.allPowerPrice = this.allPowerPrice;
                this.formData.constructionFund = this.constructionFund;
                this.formData.invoice = this.invoice;
                if (valid) {
                    const data = {...this.formData};
                    this.$client.addPowerPurchase(data).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        cb();
                    });
                }
            });
        },
    },
    created () {
        this.$client.getPowerFactor().then(({data}) => {
            this.powerFactors = data;
        });
    }
};
</script>
<style lang="less">
    .change-line {
        float: left;
        display: inline-block;
        width: 100%;
        margin-left: 10px;
        margin-top: 3px;
    }
    .el-select-dropdown__list {
        height: 174px;
    }
</style>

