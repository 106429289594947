<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions" style="margin: 20px 0 0px 20px">
            <el-button type="primary" size="mini" @click="addHandle" icon="el-icon-plus" style="border-radius: 4px">新增</el-button>
            <!-- <el-button type="primary" size="mini" @click="deleteHandle">删除</el-button> -->
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getPowerPurchaseList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
            >
            <template slot="append">
                <el-table-column
                    align="center"
                    label="操作"
                    type="action"
                    width="50"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-tooltip content="删除" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_delete"
                                @click="deleteHandle(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!--新增、编辑-->
            <el-dialog
                width="700px"
                :title="dialog.title"
                v-if="dialog.visible"
                :visible.sync="dialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <edit-form ref="editForm" :id="dialog.currentId" :type="dialog.type"></edit-form>
                <span slot="footer" class="dialog-footer">
                <el-button
                    @click="closeModalHandle"
                    size="mini"
                >{{dialog.type === 'detail' ? '关闭' : '取消'}}</el-button>
                <el-button
                    type="primary"
                    @click="submitHandle"
                    size="mini"
                    v-if="dialog.type !== 'detail'"
                >{{'确定'}}</el-button>
            </span>
            </el-dialog>
            <el-dialog :visible.sync="previewDialogVisible" :append-to-body="true">
                <img width="100%" :src="previewUrl" :on:οnerrοr="require('@/assets/images/file.png')"
                     alt="">
            </el-dialog>
        </div>
    </div>
</template>
<script>
import editForm from './editForm';
import {saveMainSubWireOut} from '../../client';

export default {
    name: 'PowerPurchaseIndex',
    components: {
        editForm
    },
    data() {
        return {
            isDeleted: '',
            selectedRows: [],
            previewDialogVisible: false,
            previewUrl: '',
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            },
        };
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'purchaseMonth',
                    label: '购电月份',
                },
                {
                    prop: 'businessCode',
                    label: '业务编号',
                },
                {
                    prop: 'invoice',
                    label: '电费发票金额',
                    formatter: (p) => {
                        return p.invoice.toFixed(2);
                    },
                },
                {
                    prop: 'allPowerPrice',
                    label: '总电费（元）',
                    formatter: (p) => {
                        return p.allPowerPrice.toFixed(2);
                    },
                },
                {
                    prop: 'purchasePower',
                    label: '购电量（kw/h）',
                    formatter: (p) => {
                        return p.purchasePower.toFixed(2);
                    },
                },
                {
                    prop: 'powerCapacity',
                    label: '用电容量（kw）',
                    formatter: (p) => {
                        return p.powerCapacity.toFixed(2);
                    },
                },
                {
                    prop: 'powerFactor',
                    label: '功率因数',
                    formatter: (p) => {
                        return p.powerFactor.toFixed(2);
                    },
                },
                {
                    prop: 'createBy',
                    label: '购电人',
                },
                {
                    prop: 'purchaseTime',
                    label: '购电时间',
                    formatter: (p) => {
                        let a = p.purchaseTime;
                        let b = '';
                        if (a != null) {
                            b = a.substr(0, 10);
                        }
                        return b;
                    },
                },
                {
                    prop: 'files',
                    label: '购电凭证',
                    renderHtml: (row) => {
                        if (row.files && row.files.length > 0) {
                            let imghtml = '';
                            for (let file of row.files) {
                                imghtml += ` <img width="20px" height="20px;" onclick="viewImgHandle('${file.id}')"  src=${this.$client.serverUrl}files/${file.id}?token=${this.$store.state.token} />`;
                            }
                            return imghtml;
                        }
                        return '';
                    }
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'purchaseMonth',
                        itemType: 'month',
                        placeholder: '请选择购电月份',
                        'value-format': 'yyyy-MM',
                    },
                ],
            };
        },
    },
    methods: {

        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        viewImgHandle(id) {
            let url = `${this.$client.serverUrl}files/${id}?token=${this.$store.state.token}`;
            this.previewUrl = url;
            this.previewDialogVisible = true;
        },
        addHandle() {
            this.dialog = {
                title: '新增购电记录',
                currentId: undefined,
                visible: true,
                type: 'add',
            };
        },
        deleteHandle(row) {
            // if (this.isDeleted === 'true') {
            //     this.$message({
            //         message: '只能对未删除数据进行删除操作！',
            //         type: 'warning',
            //     });
            //     return false;
            // } else {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning',
            }).then(() => {
                const ids = [row.id];
                this.$client.deletePowerPurchase(ids)
                    .then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        this.$refs.searchTable.searchHandler();
                    })
                    .catch(() => {
                        this.$message({
                            message: '操作失败！',
                            type: 'error',
                        });
                    });
            });
            // }
        },
        handleValidate() {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning',
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle((rest) => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        },
    },
    created() {
        window.viewImgHandle = this.viewImgHandle;
    }
};
</script>
